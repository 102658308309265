import * as React from "react"
import Layout from "../../components/layout";
import Hero from "../../components/hero";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {Button, Container, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// markup
const HowToGuidePage = () => {
    return (
        <Layout title={"How-to Guide"}>
            <main>
                <Hero title={"How-to Guide"}
                      subtitle={"Explanations of how to use the core functionality of Jurybox"}>
                    <Grid container justifyContent={"center"} alignItems={"center"}>
                        <Grid item padding={1}>
                            <Button variant={"outlined"} style={{backgroundColor: 'white'}} target="_blank"
                                    href="https://youtu.be/FsyEKIyCdd8" size={"small"}>Watch the demo</Button>
                        </Grid>
                    </Grid>
                </Hero>

                <Container style={{paddingTop: "3em", marginBottom: '3em'}}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Password reset</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Click the link: https://app.juryboxapp.com/forgotpassword. Enter the email
                                address associated with your account. You will receive a reset link in your inbox. Click
                                the link and reset your password. Note, that the link does expire after 30 minutes. If
                                you click the link after it expires, you will need to restart the process
                                again.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Create and edit a case</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Click the plus sign in the upper right hand corner on the cases screen to add a case.
                                Enter the case number, name, details, type, peremptory challenges, number of juror seats
                                and seating arrangement.
                            </Typography>
                            <br/>
                            <Typography>
                                To edit a case, click on the little pencil button once you have gone into a case. You
                                can edit the case number, name, details, type, peremptory challenges, number of juror
                                seats and seating arrangement. Editing the case details will not delete any juror
                                information, even if reducing the size of the jury box.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Delete or clone (copy) a case</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                To delete a case, click the trash can button next to the case you want to delete in case
                                list view. Delete removes a case forever and cannot be recovered.

                                To clone a case, click the clone case button next to the case you want to make a copy of
                                in case list view. Clone creates an exact replica of the case.

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Add a new juror</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurors can be added directly to the jury box or to the jury pool. Add jurors to
                                the jury box by clicking an empty seat juror seat. Add jurors to the jury pool by
                                clicking the plus button in the upper right corner of the jury pool section, below the
                                dismissed area. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Edit a juror</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurors in the jury box, dismissed area, and jury pool can be edited. To edit,
                                just click on the juror card in the jury box or dismissed area, or the juror row in the
                                jury pool.</Typography>
                            <br/>
                            <Typography>To give a juror an impression indicator (e.g. thumbs up/down) click on the three
                                vertical dots in the upper right corner of the juror card for a juror in the jury box or
                                on the juror row for jurors in the jury pool. Then choose an option: Intend to keep
                                (thumbs up), Question further (question mark), Intend to dismiss (thumbs down). To
                                remove the indicator, just click the indicator and choose the same option (shown in
                                bold).</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Dismiss a juror</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Dismiss jurors from the jury box by dragging the juror card down to the desired
                                category in the dismissed area below the jury box. To dismiss a juror from the jury
                                pool, click the x button on the juror row and select the reason for the
                                dismissal. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Jury pool</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>The jury pool is a place to enter jurors in a list view, situated below the
                                dismissed area. There is no limit to the jurors you can add here.</Typography>
                            <Typography>Jurors can be promoted to the jury box from the jury pool by clicking the
                                checkbox button on the juror row in the pool. When promoted, they will take the next
                                available seat in the jury box. You can always move a juror back into the jury pool by
                                dragging them back into the pool.</Typography>
                            <Typography>To dismiss a juror from the jury pool, click the X button on the juror row and
                                select the reason for the dismissal. To delete a juror in the jury pool permanently,
                                click the trash can button on the juror row.</Typography>
                            <Typography>You can search through the jury pool by entering text in the search area.
                                Searches include both text and demographics data. You can also sort the jury pool by
                                id/name (numbers first then letters, in alphabetical order).</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>How does Jurybox pricing work for my office?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurybox is a subscription based software, which means there is a yearly fee to
                                use it. Jurybox is offered at $399 per year per user. If your office has five
                                attorneys/support personnel that need to use the software, your office would pay $1995
                                per year. Generous discounts are available for public prosecutors and public defense
                                attorneys, as well as offices with over 5 users. Contact support@juryboxapp.com to
                                request a quote if you fall within one of those categories.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Add a note to multiple jurors at once</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Add a note to more than one juror at once by clicking the multi-box button at
                                the top of the screen. Click on the jurors you want to add the note to and confirm your
                                selection. Add the note in the Comments section and click save. The note will be
                                appended to the existing notes in the juror cards of the selected jurors. You can also
                                add demographics to multiple jurors at once with the same button.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Clear jury box, slide jurors in box, or fill from
                                pool</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>There are three convenient operations for the jury box found in the upper right
                                corner of the jury box. </Typography>
                            <Typography>The Clear Jury Box operation removes all jurors from the jury box and puts them
                                back in the jury pool.</Typography>
                            <Typography>The Slide Jurors Over operation moves jurors in the box to the next available
                                seat, filling any open seat. This action would be similar to children sliding over on a
                                park bench to fill any gaps between them.</Typography>
                            <Typography>The Fill From Pool operation takes the top jurors in the jury pool and places
                                them in the corresponding seat in the jury box, with the first person in the list taking
                                seat number 1, the second person taking seat number 2, etc.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Demographics tracking</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Besides making notes for each juror on the juror card, you can also add
                                demographics with selectable dropdowns. To add a demographic, create or edit a case and
                                type a new demographic under the Demographics Tracking Categories field. Once added,
                                those demographics categories will be added to the edit juror screen above the comments
                                section. If you choose to show demographics on juror cards, your selections will be
                                shown at the top of the juror cards, above the comments.</Typography>
                            <br/>
                            <Typography>Select the options for demographics by clicking on a juror card. In the edit
                                juror screen, you can either select from options previously input or type a new
                                selection. The options chosen will be used for demographics analytics. You can also use
                                the multiple juror edit button to apply demographics to multiple jurors at
                                once. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Demographics analytics</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>If you chose at least one demographic to track, you can view demographics
                                analytics. Access this through the small circle icon that looks like two people, in the
                                upper left corner of the Jurybox web app, or the graphs icon in the upper right corner
                                of Jurybox iPad app. </Typography>
                            <Typography>Demographics analytics has one graph for each demographic category, which
                                depicts the juror population percentage breakdown vs the breakdown of the defense and
                                prosecution peremptory strikes. For example, if you chose race for a demographic to
                                track, the graph would depict what percentage of the jury is Black, White, Asian,
                                Hispanic, etc. It would also depict what percent of the defense and prosecution
                                dismissals were Black, White, Asian, Hispanic, etc. That way, at a glance, you can
                                easily spot any bias, whether in yourself or the other party, and take action as
                                necessary.</Typography>
                            <Typography>By default, the graphs only pull data from the jury box and dismissed area, but
                                you can have it include jurors in the jury pool by selecting the option to include the
                                jury pool.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Print or email a copy of juror notes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurybox allows you to download a printer friendly PDF of juror notes for all
                                jurors. On the web app, go into the case you would like to print and click the icon of a
                                cloud with a down arrow in the upper left hand corner next to the pencil button. This
                                will download a printer friendly PDF of the juror notes for the case. Note, this feature
                                is only available on the web app (not for iPad) and does require an internet
                                connection.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Collaborate with Jurybox</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurybox allows for real time collaboration with other Jurybox subscribers who
                                are given access to a case by either sharing the case or being part of the same
                                organization and group. If more than one collaborating user is in the same case at the
                                same time, they will see real time updates from other users. </Typography>
                            <br/>
                            <Typography>The way to collaborate with people in your organization is to subscribe multiple
                                users and have them added to your organization and assigned by your admin to the same
                                group as you. All users in the same group will see any new cases that any other user in
                                that group creates. They will also be able to edit any case in their group, including
                                cases created by other members of the group. </Typography>
                            <br/>
                            <Typography>The way to collaborate with Jurybox users outside of your organization is for a
                                user to share a case with them. Do this by clicking on the Share Case button next to the
                                case you want to share. Then enter the email address of a valid Jurybox user. The case
                                will then appear in their case list for them to see and edit. If the case does not
                                appear immediately, click the refresh button in the case list screen and it will
                                appear.</Typography>
                            <br/>
                            <Typography>Note that users cannot share or delete cases that were not created by
                                them.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Add users to your organization</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>You can add additional users to your organization under the account page. To get
                                there, click the ACCOUNT button in the upper right hand corner of the Jurybox web
                                app. </Typography>
                            <br/>
                            <Typography>You can add as many users as you like during your free 30 trial. After the trial
                                is over, you will need to pay the subscription fee for each user in your
                                organization. </Typography>
                            <br/>
                            <Typography>If you have any available accounts that have already been paid for, you can
                                click the plus sign on the upper right corner of the table to add a user. Just input
                                their name, email, group and user role. Once you add them, they will receive an email
                                with an invite link to join your organization. They just need to click the link and
                                create a password to access their account.</Typography>
                            <br/>
                            <Typography>If there are no accounts available, you will either need to pay for an
                                additional account, or delete an existing user in your organization. To pay for an
                                additional account, click the edit button next to subscription info. Add one or more
                                accounts to the current cycle and click update. You will be shown the prorated amount
                                for the additional users added. Click YES to proceed with the purchase.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Use Jurybox offline</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Even though Jurybox is a Cloud based app, it will still continue to work even
                                when you temporarily loose internet access. The only difference is that you will not see
                                contributions from others while you are offline. Everything else will work the same as
                                in online mode. Once you reestablish your connection, Jurybox will sync up with the
                                Cloud. Note that when you are offline, a red bar with the words "Offline" will show at
                                the top of the screen. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Enable multi-factor authentication (MFA)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Multi-factor authentication (MFA) is an optional security feature that requires
                                input of an authentication token that you would enter after a username and password.
                                This increases your account security in case someone acquires your username and
                                password.</Typography>
                            <br/>
                            <Typography>An admin can require MFA for all users in their organization by clicking the
                                toggle "Require MFA for all users" on the Account page. If this is enabled, all users in
                                an organization would need to setup MFA before signing into their Jurybox account. An
                                individual user can also setup MFA for their own account by clicking the MFA Setup
                                button on the Account page.</Typography>
                            <br/>
                            <Typography>To setup MFA, you will need to enable one or more channels and select a
                                preferred channel. A channel is a means to receive an authentication token which needs
                                to be entered on sign up. Options include Email, SMS (i.e. text message), and
                                Authenticator app. Email is configured by default and can always be used as
                                backup. </Typography>
                            <br/>
                            <Typography>To configure SMS for MFA, toggle on SMS option in MFA Setup and enter your phone
                                number. You will receive a text message with a token that you enter. If everything
                                worked, you will see a green check appear by SMS. Once configured, select SMS as your
                                preferred channel.</Typography>
                            <br/>
                            <Typography>To configure an Authenticator App for MFA toggle on Authenticator App option in
                                MFA Setup. Make sure you download an Authenticator App like Google Authenticator,
                                Microsoft Authenticator, Authy, etc. Use the app to scan the QR code. The app will
                                generate a token which you enter into the space provided. If everything worked, you will
                                see a green check appear by Authenticator App. Once configured, select Authenticator App
                                as your preferred channel.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Customize jury box layout with gaps and aisles</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Represent the actual layout of the courtroom's jury box by using the gaps
                                feature.</Typography>
                            <br/>
                            <Typography variant={"overline"}>Web</Typography>
                            <Typography>Place your mouse over an empty seat in the jury box and you'll notice a small
                                icon in the upper right-hand corner, just below the number. Click on that to add a gap.
                                You can click on the center "-" icon of any gap to remove it at any time.</Typography>
                            <br/>
                            <Typography variant={"overline"}>iPad</Typography>
                            <Typography>Press on the "..." icon on the upper right-hand corner of the jury box and
                                select "Show spacer buttons". You'll see buttons available to add or remove gaps in the
                                jury box layout.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </main>
        </Layout>
    );
};

export default HowToGuidePage;
